import React from 'react';

function Role() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
    padding: '20px', 
    margin: 0,
    textAlign: 'center'
  };

  return (
    <div style={style}>
      <h2>Coming Soon</h2>
    </div>
  );
}

export default Role;
