import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Autocomplete, TextField } from "@mui/material";
import axios from "../../../../axios";
// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

const decimal_places = [
  { label: "0", value: "0" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
];

const format = ["1,234,567", "1,234,567.89", "1,234,567.899"];

function EditCurrency() {
  const { id } = useParams();

  const [currencyList, setcurrencyList] = useState([]);
  const [value, setValue] = useState();
  const [selectedCategory, setselectedCategory] = useState("");
  const [selectedValue, setselectedValue] = useState({
    name: "",
    currency_master_id: "",
    format: "",
    status: 1,
    uuid: "",
    id: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setselectedValue({
      ...selectedValue,
      [name]: value,
    });
  };

  const Currencyoptions = async () => {
    try {
      const response = await axios.get("all-currency");
      const { data } = response;
      let currency = [];
      data.data.map((data) => {
        let Object = {
          label: data.name,
          value: data.id,
          ...data,
        };
        currency.push(Object);
      });
      setcurrencyList(currency);
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrencyDetails = async () => {
    const response = await axios
      .get("currency/edit/" + id)
      .then((response) => {
        const { data } = response?.data;
        setselectedValue({ ...data });
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  useEffect(() => {
    Currencyoptions();
    getCurrencyDetails();
  }, []);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    selectedValue.currency_master_id = selectedCategory.value;
    selectedValue.name = selectedCategory.label;
    selectedValue.symbol = selectedCategory.symbol;
    selectedValue.code = selectedCategory.code;
    selectedValue.name_plural = selectedCategory.label;
    selectedValue.symbol_native = selectedCategory.symbol_native;
    selectedValue.decimal_digits = selectedCategory.decimal_digits;
    selectedValue.rounding = selectedCategory.rounding;
    selectedValue.default_currency = 0;
    selectedValue.format = value;
    await axios
      .post("currency/edit/" + id, selectedValue)
      .then((response) => {
        navigate("/currency");
        toast.success("Data edit Successfully");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={9}>
            <Card component="form" role="form" onSubmit={handleSubmit}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edit Currency
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <Grid item xs={6} pb={2}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={currencyList}
                      // style={{ height: 45 }}
                      sx={{ width: 300 }}
                      value={Object.keys(selectedCategory).length > 0 ? selectedCategory : ""}
                      onChange={(event, newValue) => setselectedCategory(newValue)}
                      renderInput={(params) => <TextField {...params} label="Currency Code" />}
                    ></Autocomplete>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox pb={2}>
                      <MDInput
                        type="text"
                        label="Currency Symbol"
                        variant="outlined"
                        sx={{ width: 300 }}
                        value={
                          Object.keys(selectedCategory).length > 0 ? selectedCategory.symbol : ""
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MDBox pb={2}>
                      <MDInput
                        type="text"
                        label="Currency Name"
                        variant="outlined"
                        sx={{ width: 300 }}
                        value={
                          Object.keys(selectedCategory).length > 0 ? selectedCategory.name : ""
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6} pb={2}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={decimal_places}
                      // style={{ height: 45 }}
                      sx={{ width: 300 }}
                      value={
                        Object.keys(selectedCategory).length > 0
                          ? selectedCategory.decimal_digits
                          : ""
                      }
                      renderInput={(params) => <TextField {...params} label="Decimal Places" />}
                    ></Autocomplete>
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={format}
                      value={selectedValue?.value}
                      onChange={(event, newValue) => {
                        console.log(newValue);
                        setValue(newValue);
                      }}
                      // style={{ height: 45 }}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Format" />}
                    ></Autocomplete>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={2} mr={3}>
                  <MDTypography component={Link} to="/currency">
                    <MDButton variant="gradient" color="info" fullWidth>
                      Cancel
                    </MDButton>
                  </MDTypography>
                </Grid>
                <Grid item xs={2} ml={3}>
                  <MDBox>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditCurrency;
