/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import * as React from "react";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

// @mui icons
import GoogleIcon from "@mui/icons-material/Google";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { Autocomplete, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
// import { ArrowDropDown, ArrowDropDownCircle } from "@mui/icons-material";
// import { Select } from "@mui/material";

const countries = [
  { value: "Andorra", label: "Andorra" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Albania", label: "Albania" },
  { value: "Armenia", label: "Armenia" },
  { value: "Angola", label: "Angola" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Argentina", label: "Argentina" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Austria", label: "Austria" },
  { value: "Australia", label: "Australia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Alland Islands", label: "Alland Islands" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Barbados", label: "Barbados" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Belgium", label: "Belgium" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Burundi", label: "Burundi" },
  { value: "Benin", label: "Benin" },
  { value: "Saint Barthelemy", label: "Saint Barthelemy" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Brazil", label: "Brazil" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Botswana", label: "Botswana" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belize", label: "Belize" },
  { value: "Canada", label: "Canada" },
  { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { value: "Congo, Democratic Republic of the", label: "Congo, Democratic Republic of the" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Congo, Republic of the", label: "Congo, Republic of the" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Chile", label: "Chile" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Curacao", label: "Curacao" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Germany", label: "Germany" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Denmark", label: "Denmark" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Algeria", label: "Algeria" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Estonia", label: "Estonia" },
  { value: "Egypt", label: "Egypt" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Spain", label: "Spain" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Finland", label: "Finland" },
  { value: "Fiji", label: "Fiji" },
  { value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
  { value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Grenada", label: "Grenada" },
  { value: "Georgia", label: "Georgia" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greenland", label: "Greenland" },
  { value: "Gambia", label: "Gambia" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Greece", label: "Greece" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guam", label: "Guam" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands" },
  { value: "Honduras", label: "Honduras" },
  { value: "Croatia", label: "Croatia" },
  { value: "Haiti", label: "Haiti" },
  { value: "Hungary", label: "Hungary" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "India", label: "India" },
  { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
  { value: "Iraq", label: "Iraq" },
  { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
  { value: "Iceland", label: "Iceland" },
  { value: "Italy", label: "Italy" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Jordan", label: "Jordan" },
  { value: "Japan", label: "Japan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Comoros", label: "Comoros" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  {
    value: "Korea, Democratic People's Republic of",
    label: "Korea, Democratic People's Republic of",
  },
  { value: "Korea, Republic of", label: "Korea, Republic of" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Liberia", label: "Liberia" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Latvia", label: "Latvia" },
  { value: "Libya", label: "Libya" },
  { value: "Morocco", label: "Morocco" },
  { value: "Monaco", label: "Monaco" },
  { value: "Moldova, Republic of", label: "Moldova, Republic of" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Saint Martin (French part)", label: "Saint Martin (French part)" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  {
    value: "Macedonia, the Former Yugoslav Republic of",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  { value: "Mali", label: "Mali" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Macao", label: "Macao" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Malta", label: "Malta" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Maldives", label: "Maldives" },
  { value: "Malawi", label: "Malawi" },
  { value: "Mexico", label: "Mexico" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Namibia", label: "Namibia" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "Niger", label: "Niger" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Norway", label: "Norway" },
  { value: "Nepal", label: "Nepal" },
  { value: "Nauru", label: "Nauru" },
  { value: "Niue", label: "Niue" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Oman", label: "Oman" },
  { value: "Panama", label: "Panama" },
  { value: "Peru", label: "Peru" },
  { value: "French Polynesia", label: "French Polynesia" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Poland", label: "Poland" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Palestine, State of", label: "Palestine, State of" },
  { value: "Portugal", label: "Portugal" },
  { value: "Palau", label: "Palau" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Serbia", label: "Serbia" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sudan", label: "Sudan" },
  { value: "Sweden", label: "Sweden" },
  { value: "Singapore", label: "Singapore" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "San Marino", label: "San Marino" },
  { value: "Senegal", label: "Senegal" },
  { value: "Somalia", label: "Somalia" },
  { value: "Suriname", label: "Suriname" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Chad", label: "Chad" },
  { value: "French Southern Territories", label: "French Southern Territories" },
  { value: "Togo", label: "Togo" },
  { value: "Thailand", label: "Thailand" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Tonga", label: "Tonga" },
  { value: "Turkey", label: "Turkey" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Taiwan, Republic of China", label: "Taiwan, Republic of China" },
  { value: "United Republic of Tanzania", label: "United Republic of Tanzania" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "Uganda", label: "Uganda" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
  { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "British Virgin Islands", label: "British Virgin Islands" },
  { value: "US Virgin Islands", label: "US Virgin Islands" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Samoa", label: "Samoa" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Yemen", label: "Yemen" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "South Africa", label: "South Africa" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

function Cover() {
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign Up
          </MDTypography>
          {/* <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="First Name" variant="standard" fullWidth />
              <MDInput type="text" label="Last Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth />
              <MDInput type="text" label="Phone No" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" fullWidth />
              <MDInput type="password" label="Confirm Password" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countries}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Country" />}
              ></Autocomplete>
              {/* <Select options={options} value={value} onChange={changeHandler} /> */}
            </MDBox>
          </MDBox>
          <MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms of Service
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                and
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Privacy Policy
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth>
                sign up
              </MDButton>
            </MDBox>
            <MDBox>
              <hr></hr>
            </MDBox>
            <MDBox mt={1} textAlign="center">
              <MDTypography component={MuiLink} href="#" variant="button" color="text" fullWidth>
                <GoogleIcon color="inherit" fullWidth />
                Sign In With Google
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
