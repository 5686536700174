/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Customer from "pages/customer";
import ItemBrandMst from "pages/itemBrandMst";
import ItemFamMst from "pages/ItemFamMst";
import Item from "pages/item";
import Itemcate from "pages/itemcate";
import Itemcolor from "pages/itemcolor";
import ItemDeptMst from "pages/itemDeptMst";
import Sizemaster from "pages/sizemaster";
import Supplier from "pages/supplier";
import Salesman from "pages/salesman";
import Journey_Plans from "pages/journey_plans";
import Promotion from "pages/promotion";
import Pricing from "pages/pricing";
import Discount from "pages/discount";
import Rebate from "pages/rebate";
import Listing_Fee from "pages/listing_fee";
import Shelf_Rent from "pages/shelf_rent";
import Orders from "pages/orders";
import Purchase_order from "pages/purchaseorder";
import Delivery from "pages/delivery";
import ItemSFamMst from "pages/ItemSFamMst";

import Invoice from "pages/invoice";
import Table from "pages/invoice/table";
import Credit_Notes from "pages/credit_notes";
import Debit_Notes from "pages/debit_notes";
import Collection from "pages/collections";
import Route_item_group from "pages/route_item_group";
import Portfolio_Management from "pages/portfolio_management";
import Salesman_load from "pages/salesman_load";
import Load_Request from "pages/load_request";
import Salesman_Unload from "pages/salesman_unload";
import Van_To_Van_Transfer from "pages/van_to_van_transfer";
import Goods_Receipt_Notes from "pages/grn";
import Cashier_Reciept from "pages/cashier_reciept";
import PDC from "pages/pdc";
import Session_Endorsement from "pages/session_endorsement";
import Sales_Target from "pages/sales_target";
import Target_Commission from "pages/target_commission";
import Expense from "pages/expense";
// import Master from "pages/master";
import User_Roles from "pages/master/users_roles";
import Change_Password from "pages/master/change_password";
import Master_Data_Download from "pages/master/master_data_download";
import Custom_Fields from "pages/master/preferences/custom_fields";
import General_Settings from "pages/master/preferences/general_settings";
import Workflow_Rules from "pages/master/preferences/workflow_rules";
import Templates from "pages/master/preferences/templates";
import Activity_Profile from "pages/master/preferences/activity_profile";
import Theme from "pages/master/preferences/theme";
import Portal from "pages/master/preferences/portal";
import Bank from "pages/master/bank";
import Taxes from "pages/master/taxes";
import Reason from "pages/master/reason";
import Currency from "pages/master/currency";
import Warehouse from "pages/master/warehouse";
import Country from "pages/master/location/country";
import Region from "pages/master/location/region_master";
import Branch_Depot from "pages/master/location/branch_master";
import VanMaster from "pages/master/location/van_master";
import RouteMaster from "pages/master/location/route_master";
import Outlet_product from "pages/master/customer_setting/outlet_product_code";
import Credit_Limits from "pages/master/customer_setting/credit_limits";
import ItemGroup from "pages/master/item_setting/item_group";
import ItemUOM from "pages/master/item_setting/uom";
import ItemVariant from "pages/master/item_setting/variant";
import ItemClass from "pages/master/item_setting/class";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Forget from "layouts/authentication/forget-password";

//Add new page routes
import Addnew from "pages/customer/add_new";

import Add_Item from "pages/item/add_item";
import Add_ItemSFamMst from "pages/ItemSFamMst/add_ItemSFamMst";
import Add_ItemBrandMst from "pages/itemBrandMst/add_ItemBrandMst";

import Add_Itemcate from "pages/itemcate/add_itemcate";
import Add_ItemFamDep from "pages/ItemFamMst/add_ItemFamMst"
import Add_Itemcolor from "pages/itemcolor/add_itemcolor";
import Add_Itemdepart from "pages/itemDeptMst/add_ItemDeptMst";
import Add_Sizemaster from "pages/sizemaster/add_size";
import Add_supplier from "pages/supplier/add_supplier";
import Add_Journey_plans from "pages/journey_plans/add_journey_plans";
import Add_Salesman from "pages/salesman/add_salesman";
import Add_Promotion from "pages/promotion/add_promotion";
import Add_Pricing from "pages/pricing/add_pricing";
import Add_Discount from "pages/discount/add_discount";
import Add_Rebate from "pages/rebate/add_rebate";
import Add_Orders from "pages/orders/add_orders";
import Add_Po from "pages/purchaseorder/add_po";
import Add_Delivery from "pages/delivery/add_delivery";
import Add_Invoice from "pages/invoice/add_invoice";
import Add_Credit_Notes from "pages/credit_notes/add_credit_notes";
import Add_Debit_Notes from "pages/debit_notes/add_debit_notes";
import Add_Salesman_load from "pages/salesman_load/add_salesman_load";
import Add_load_Request from "pages/load_request/add_load_request";
import AddBank from "pages/master/bank/add_bank";
import AddTax from "pages/master/taxes/add_tax";
import AddReason from "pages/master/reason/add_reason";
import AddCurrency from "pages/master/currency/add_currency";
import AddWarehouse from "pages/master/warehouse/add_warehouse";
import AddCountry from "pages/master/location/country/add_country";
import AddRegion from "pages/master/location/region_master/add_region";
import AddBranch from "pages/master/location/branch_master/add_branch_master";
import AddVanMaster from "pages/master/location/van_master/add_van_master";
import AddRoute from "pages/master/location/route_master/add_route";
import AddOutlet_product from "pages/master/customer_setting/outlet_product_code/add_outlet_product_code";
import AddItemGroup from "pages/master/item_setting/item_group/add_item_group";
import AddItemUOM from "pages/master/item_setting/uom/add_uom";
import AddListing_Fee from "pages/listing_fee/add_listing_fee";
import AddShelf_rent from "pages/shelf_rent/add_shelf_rent";
import Invite_user from "pages/master/users_roles/invite_user.js";
import Add_GRN from "pages/grn/add_grn";
import Add_Collections from "pages/collections/add_collections";
import AddRoute_item_group from "pages/route_item_group/add_route_item_group";
import AddPortfolio_management from "pages/portfolio_management/add_portfolio_management";
import Add_Cashier_Reciept from "pages/cashier_reciept/add_cashier_reciept";
import Add_PDC from "pages/pdc/add_pdc";
import Add_Target_Commission from "pages/target_commission/add_target_commission";
import Add_Expense from "pages/expense/add_expense";
import AddRole from "pages/master/users_roles/roles/new_role";
import Add_VanToVan_Transfer from "pages/van_to_van_transfer/add_vantovan_transfer";
import Add_JDE_Config from "pages/master/jde_config/add_jde_config";

// @mui icons
import Icon from "@mui/material/Icon";

//Edit pages route
import EditNew from "pages/customer/edit_new";
import EditItemBrandMst from "pages/itemBrandMst/edit_ItemBrandMst";
import EditItemFm from "pages/ItemFamMst/edit_ItemFamMst"
import Edit_Item from "pages/item/edit_item";
import Edit_itemDeptMst from "pages/itemDeptMst/edit_ItemDeptMst"
import Edit_itemcate from "pages/itemcate/edit_itemcate";
import Edit_Itemcolor from "pages/itemcolor/edit_itemcolor";
import Edit_Sizemaster from "pages/sizemaster/edit_size";
import Edit_supplier from "pages/supplier/edit_supplier";
import Edit_Salesman from "pages/salesman/edit_salesman";
import Edit_Journey_plans from "pages/journey_plans/edit_journey_plans";
import Edit_Promotion from "pages/promotion/edit_promotion";
import Edit_Pricing from "pages/pricing/edit_pricing";
import Edit_Discount from "pages/discount/edit_discount";
import Edit_Orders from "pages/orders/edit_orders";
import Edit_Po from "pages/purchaseorder/edit_po";
import View_Orders from "pages/orders/view_orders";
import View_Orders_po from "pages/purchaseorder/view_orders_po";
import Generate_Invoice from "pages/orders/generate_invoice";
import Generate_Invoice_po from "pages/purchaseorder/generate_invoice-po";
import User from "pages/User";
import Setting from "pages/Setting";
import Role from "pages/Role";
import Report from "pages/reports";
import Items from "pages/Items";


import Edit_Delivery from "pages/delivery/edit_delivery";
import Edit_Invoice from "pages/invoice/edit_invoice";
import Edit_ItemSFamMst from "pages/ItemSFamMst/edit_ItemSFamMst";


import Edit_Credit_Notes from "pages/credit_notes/edit_credit_notes";
import Edit_Debit_Notes from "pages/debit_notes/edit_debit_notes";
import EditRoute_item_group from "pages/route_item_group/edit_route_item_group";
import EditPortfolio_management from "pages/portfolio_management/edit_portfolio_management";
import Edit_load_Request from "pages/load_request/edit_load_request";
import Edit_GRN from "pages/grn/edit_grn";
import Edit_PDC from "pages/pdc/edit_pdc";
import Edit_Target_Commission from "pages/target_commission/edit_target_commission";
import EditTax from "pages/master/taxes/edit_tax";
import EditReason from "pages/master/reason/edit_reason";
import EditBank from "pages/master/bank/edit_bank";
import EditCurrency from "pages/master/currency/edit_currency";
import EditWarehouse from "pages/master/warehouse/edit_warehouse";
import EditCountry from "pages/master/location/country/edit_country";
import EditRegion from "pages/master/location/region_master/edit_region";
import EditBranch from "pages/master/location/branch_master/edit_branch_master";
import EditVanMaster from "pages/master/location/van_master/edit_van_master";
import EditRoute from "pages/master/location/route_master/edit_route";
import EditOutlet_product from "pages/master/customer_setting/outlet_product_code/edit_outlet_product_code";
import EditItemGroup from "pages/master/item_setting/item_group/edit_item_group";
import EditItemUOM from "pages/master/item_setting/uom/edit_uom";
import Item_Batch from "pages/master/item_batch";
import JDE_Config from "pages/master/jde_config";
import Edit_VanToVan_Transfer from "pages/van_to_van_transfer/edit_vantovan_transfer";
import Edit_JDE_Config from "pages/master/jde_config/edit_jde_config";
import Add_Activity from "pages/master/preferences/activity_profile/add_activity";
import Add_Workflow from "pages/master/preferences/workflow_rules/add_workflow";


export const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Customer",
  //   key: "customer",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/customer",
  //   component: <Customer />,
  // },
  // {
  //   type: "collapse",
  //   name: "Users & Roles",
  //   key: "users",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/users",
  //   component: <User_Roles />,
  // },
 
  // {
  //   type: "collapse",
  //   name: "Master Data Download",
  //   key: "master-download",
  //   icon: <Icon fontSize="small">download</Icon>,
  //   route: "/master-download",
  //   component: <Master_Data_Download />,
  // },
  // {
  //   type: "collapse",
  //   name: "Custom Fields",
  //   key: "custom-fields",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/custom-fields",
  //   component: <Custom_Fields />,
  // },
  // {
  //   type: "collapse",
  //   name: "General Settings",
  //   key: "general-range",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/general-range",
  //   component: <General_Settings />,
  // },
  // {
  //   type: "collapse",
  //   name: "Work Flow Approval",
  //   key: "work-flow",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/work-flow",
  //   component: <Workflow_Rules />,
  // },
  // {
  //   type: "collapse",
  //   name: "Templates",
  //   key: "templates",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/templates",
  //   component: <Templates />,
  // },
  // {
  //   type: "collapse",
  //   name: "Activity Profile",
  //   key: "activity-profile",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/activity-profile",
  //   component: <Activity_Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Theme",
  //   key: "theme",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/theme",
  //   component: <Theme />,
  // },
  // {
  //   type: "collapse",
  //   name: "Portal",
  //   key: "clientport",
  //   // icon: <Icon fontSize="small">person</Icon>,
  //   route: "/clientport",
  //   component: <Portal />,
  // },
  // {
  //   type: "collapse",
  //   name: "Reason",
  //   key: "reason",
  //   icon: <Icon fontSize="small">foundation</Icon>,
  //   route: "/reason",
  //   component: <Reason />,
  // },
  // {
  //   type: "collapse",
  //   name: "Bank",
  //   key: "bank",
  //   icon: <Icon fontSize="small">account_balance</Icon>,
  //   route: "/bank",
  //   component: <Bank />,
  // },
  // {
  //   type: "collapse",
  //   name: "Currency",
  //   key: "currency",
  //   icon: <Icon fontSize="small">money</Icon>,
  //   route: "/currency",
  //   component: <Currency />,
  // },
  // {
  //   type: "collapse",
  //   name: "Warehouse",
  //   key: "warehouse",
  //   icon: <Icon fontSize="small">foundation</Icon>,
  //   route: "/warehouse",
  //   component: <Warehouse />,
  // },
  // {
  //   type: "collapse",
  //   name: "Item Batch",
  //   key: "item-batch",
  //   icon: <Icon fontSize="small">view_in_ar</Icon>,
  //   route: "/item-batch",
  //   component: <Item_Batch />,
  // },
  // {
  //   type: "collapse",
  //   name: "JDE Config",
  //   key: "jd-version-config",
  //   icon: <Icon fontSize="small">view_in_ar</Icon>,
  //   route: "/jd-version-config",
  //   component: <JDE_Config />,
  // },
  // {
  //   type: "collapse",
  //   name: "Country",
  //   key: "country",
  //   icon: <Icon fontSize="small">public</Icon>,
  //   route: "/country",
  //   component: <Country />,
  // },
  // {
  //   type: "collapse",
  //   name: "Region",
  //   key: "region",
  //   icon: <Icon fontSize="small">signpost</Icon>,
  //   route: "/region",
  //   component: <Region />,
  // },
  // {
  //   type: "collapse",
  //   name: "Branch/Depot",
  //   key: "depot",
  //   icon: <Icon fontSize="small">foundation</Icon>,
  //   route: "/depot",
  //   component: <Branch_Depot />,
  // },
  // {
  //   type: "collapse",
  //   name: "VanMaster",
  //   key: "van",
  //   icon: <Icon fontSize="small">foundation</Icon>,
  //   route: "/van",
  //   component: <VanMaster />,
  // },
  // {
  //   type: "collapse",
  //   name: "Route",
  //   key: "route",
  //   icon: <Icon fontSize="small">map</Icon>,
  //   route: "/route",
  //   component: <RouteMaster />,
  // },
  // {
  //   type: "collapse",
  //   name: "Credit Limits",
  //   key: "credit-limits",
  //   icon: <Icon fontSize="small">credit_card</Icon>,
  //   route: "/credit-limits",
  //   component: <Credit_Limits />,
  // },
  // {
  //   type: "collapse",
  //   name: "Outlet Product Code",
  //   key: "outlet-product-code",
  //   icon: <Icon fontSize="small">description</Icon>,
  //   route: "/outlet-product-code",
  //   component: <Outlet_product />,
  // },
  // {
  //   type: "collapse",
  //   name: "Item Group",
  //   key: "item-group",
  //   icon: <Icon fontSize="small">add_box</Icon>,
  //   route: "/item-group",
  //   component: <ItemGroup />,
  // },

  // {
  //   type: "collapse",
  //   name: "Variant",
  //   key: "variant",
  //   icon: <Icon fontSize="small">view_week</Icon>,
  //   route: "/variant",
  //   component: <ItemVariant />,
  // },
  // {
  //   type: "collapse",
  //   name: "Class",
  //   key: "class",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/class",
  //   component: <ItemClass />,
  // },
  // // {
  // //   type: "collapse",
  // //   name: "Master",
  // //   key: "master",
  // //   route: "/master",
  // //   component: <Master />,
  // // },
  {
    type: "collapse",
    name: "Item",
    key: "item",
    icon: <Icon fontSize="small">Item</Icon>,
    route: "/item",
    component: <Item />,
  },
  {
    type: "collapse",
    name: "Item Department",
    key: "itemDeptMst",
    icon: <Icon fontSize="small">foundation</Icon>,
    route: "/itemDeptMst",
    component: <ItemDeptMst />,
  },
  {
    type: "collapse",
    name: "Brand Master",
    key: "ItemBrandMst",
    icon: <Icon fontSize="small">foundation</Icon>,
    route: "/ItemBrandMst",
    component: <ItemBrandMst />,
  },
  {
    type: "collapse",
    name: "Employee",
    key: "Employee",
    icon: <Icon fontSize="small">cases</Icon>,
    route: "/salesman",
    component: <Salesman />,
  },
  // {
  //   type: "collapse",
  //   name: "Item Family Department",
  //   key: "ItemFamDep",
  //   icon: <Icon fontSize="small">cases</Icon>,
  //   route: "/ItemFamDep",
  //   component: <Add_ItemFamDep />,
  // },

  // {
  //   type: "collapse",
  //   name: "Journey Plans",
  //   key: "Journey Plans",
  //   icon: <Icon fontSize="small">layers</Icon>,
  //   route: "/journey-plan",
  //   component: <Journey_Plans />,
  // },
  // {
  //   type: "collapse",
  //   name: "Promotion",
  //   key: "promotion",
  //   icon: <Icon fontSize="small">redeem</Icon>,
  //   route: "/promotion",
  //   component: <Promotion />,
  // },
  // {
  //   type: "collapse",
  //   name: "Pricing",
  //   key: "pricing",
  //   icon: <Icon fontSize="small">attach_money</Icon>,
  //   route: "/pricing",
  //   component: <Pricing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Discount",
  //   key: "discount",
  //   icon: <Icon fontSize="small">percent</Icon>,
  //   route: "/discount",
  //   component: <Discount />,
  // },
  // {
  //   type: "collapse",
  //   name: "Rebate",
  //   key: "rebate",
  //   icon: <Icon fontSize="small">card_membership</Icon>,
  //   route: "/rebate",
  //   component: <Rebate />,
  // },
  // {
  //   type: "collapse",
  //   name: "Listing Fee",
  //   key: "listing-fee",
  //   icon: <Icon fontSize="small">card_membership</Icon>,
  //   route: "/listing-fee",
  //   component: <Listing_Fee />,
  // },
  // {
  //   type: "collapse",
  //   name: "Shelf Rent",
  //   key: "shelf-rent",
  //   icon: <Icon fontSize="small">card_membership</Icon>,
  //   route: "/shelf-rent",
  //   component: <Shelf_Rent />,
  // },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/order",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Purchase Order",
    key: "purchaseorder",
    icon: <Icon fontSize="small">receipt</Icon>,
    route: "/purchaseorder",
    component: <Purchase_order />,
  },

  {
    type: "collapse",
    name: "UOM",
    key: "item-uom",
    icon: <Icon fontSize="small">balance</Icon>,
    route: "/item-uom",
    component: <ItemUOM />,
  },

  {
    type: "collapse",
    name: "Tax Master",
    key: "taxes",
    icon: <Icon fontSize="small">percent</Icon>,
    route: "/taxes",
    component: <Taxes />,
  },
  {
    type: "collapse",
    name: "Supplier",
    key: "supplier",
    icon: <Icon fontSize="small">view_week</Icon>,
    route: "/supplier",
    component: <Supplier />,
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customer",
    component: <Customer />,
  },
  // {
  //   type: "collapse",
  //   name: "User",
  //   key: "User",
  //   icon: <Icon fontSize="small">person</Icon>, // User-related icon
  //   route: "/user",
  //   component: <User />,
  // },
  {
    type: "collapse",
    name: "Role",
    key: "Role",
    icon: <Icon fontSize="small">security</Icon>, // Role-related icon
    route: "/role",
    component: <Role />,
  },
  // {
  //   type: "collapse",
  //   name: "Item",
  //   key: "item",
  //   icon: <Icon fontSize="small">shopping_bag</Icon>,
  //   route: "/item",
  //   component: <Item />,
  // },
  {
    type: "collapse",
    name: "Sub Family Master",
    key: "ItemSFamMst",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/ItemSFamMst",
    component: <ItemSFamMst />,
  },
  {
    type: "collapse",
    name: "Item Category",
    key: "itemcate",
    icon: <Icon fontSize="small">text_snippet</Icon>,
    route: "/itemcate",
    component: <Itemcate />,
  },
  {
    type: "collapse",
    name: "Item Color",
    key: "itemcolor",
    icon: <Icon fontSize="small">shopping_bag</Icon>,
    route: "/itemcolor",
    component: <Itemcolor />,
  },
  {
    type: "collapse",
    name: "Family Master",
    key: "ItemFamMst",
    icon: <Icon fontSize="small">local_shipping</Icon>,
    route: "/ItemFamMst",
    component: <ItemFamMst />,
  },
  {
    type: "collapse",
    name: "Size Master",
    key: "sizemaster",
    icon: <Icon fontSize="small">event_available</Icon>,
    route: "/sizemaster",
    component: <Sizemaster />,
  },
  {
    type: "collapse",
    name: "Setting",
    key: "Setting",
    icon: <Icon fontSize="small">settings</Icon>, // Settings-related icon
    route: "/setting",
    component: <Setting />,
  },
  // {
  //   type: "collapse",
  //   name: "Delivery",
  //   key: "delivery",
  //   icon: <Icon fontSize="small">local_shipping</Icon>,
  //   route: "/delivery",
  //   component: <Delivery />,
  // },
  {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <Icon fontSize="small">text_snippet</Icon>,
    route: "/invoice",
    component: <Invoice />,
  },
  // {
  //   type: "collapse",
  //   name: "Table",
  //   key: "Table",
  //   icon: <Icon fontSize="small">text_snippet</Icon>,
  //   route: "/table",
  //   component: <Table />,
  // },
  // {
  //   type: "collapse",
  //   name: "Credit Note",
  //   key: "credit-note",
  //   icon: <Icon fontSize="small">credit_card</Icon>,
  //   route: "/credit-note",
  //   component: <Credit_Notes />,
  // },
  // {
  //   type: "collapse",
  //   name: "Debit Note",
  //   key: "debit-note",
  //   icon: <Icon fontSize="small">credit_card</Icon>,
  //   route: "/debit-note",
  //   component: <Debit_Notes />,
  // },
  // {
  //   type: "collapse",
  //   name: "Collection",
  //   key: "collection",
  //   icon: <Icon fontSize="small">event_available</Icon>,
  //   route: "/collection",
  //   component: <Collection />,
  // },
  // {
  //   type: "collapse",
  //   name: "Route Item Grouping",
  //   key: "route-item-group",
  //   icon: <Icon fontSize="small">layers</Icon>,
  //   route: "/route-item-group",
  //   component: <Route_item_group />,
  // },
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/portfolio-management",
    component: <Portfolio_Management />,
  },
  // {
  //   type: "collapse",
  //   name: "Salesman Load",
  //   key: "salesman-load",
  //   icon: <Icon fontSize="small">local_shipping</Icon>,
  //   route: "/salesman-load",
  //   component: <Salesman_load />,
  // },
  // {
  //   type: "collapse",
  //   name: "Load Request",
  //   key: "load-request",
  //   icon: <Icon fontSize="small">local_shipping</Icon>,
  //   route: "/load-request",
  //   component: <Load_Request />,
  // },
  // {
  //   type: "collapse",
  //   name: "Salesman Unload",
  //   key: "salesman-unload",
  //   icon: <Icon fontSize="small">local_shipping</Icon>,
  //   route: "/salesman-unload",
  //   component: <Salesman_Unload />,
  // },
  // {
  //   type: "collapse",
  //   name: "Van to Van Transfer",
  //   key: "van-to-van-transfer",
  //   icon: <Icon fontSize="small">local_shipping</Icon>,
  //   route: "/van-to-van-transfer",
  //   component: <Van_To_Van_Transfer />,
  // },
  {
    type: "collapse",
    name: "GRN",
    key: "grn",
    icon: <Icon fontSize="small">sticky_note_2</Icon>,
    route: "/grn",
    component: <Goods_Receipt_Notes />,
  },
  {
    type: "collapse",
    name: "Report",
    key: "Report",
    icon: <Icon fontSize="small">security</Icon>, // report-related icon
    route: "/report",
    component: <Report />,
  },
  // {
  //   type: "collapse",
  //   name: "Cashier Reciept",
  //   key: "cashier-reciept",
  //   icon: <Icon fontSize="small">text_snippet</Icon>,
  //   route: "/cashier-reciept",
  //   component: <Cashier_Reciept />,
  // },
  // {
  //   type: "collapse",
  //   name: "PDC",
  //   key: "pdc",
  //   icon: <Icon fontSize="small">edit_note</Icon>,
  //   route: "/pdc",
  //   component: <PDC />,
  // },
  // {
  //   type: "collapse",
  //   name: "Session Endorsement",
  //   key: "session",
  //   icon: <Icon fontSize="small">thumb_up_off_alt</Icon>,
  //   route: "/session",
  //   component: <Session_Endorsement />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sales Target",
  //   key: "sales-target",
  //   icon: <Icon fontSize="small">crisis_alert</Icon>,
  //   route: "/sales-target",
  //   component: <Sales_Target />,
  // },
  // {
  //   type: "collapse",
  //   name: "Target Commission",
  //   key: "target-comission",
  //   icon: <Icon fontSize="small">crisis_alert</Icon>,
  //   route: "/target-comission",
  //   component: <Target_Commission />,
  // },
  // {
  //   type: "collapse",
  //   name: "Expense",
  //   key: "expense",
  //   icon: <Icon fontSize="small">money</Icon>,
  //   route: "/expense",
  //   component: <Expense />,
  // },
  // {
  //   type: "collapse",
  //   name: "Reports",
  //   key: "reports",
  //   icon: <Icon fontSize="small">text_snippet</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    // type: "collapse",
    // name: "Sign In",
    key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    // name: "Sign Up",
    key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/signup",
    component: <SignUp />,
  },
  {
    route: "/auth/forget",
    component: <Forget />,
  },

  //Add new pages route
  {
    key: "add-new",
    route: "/master/customer",
    component: <Addnew />,
  },
  // {
  //   key: "add-bank",
  //   route: "/master/bank",
  //   component: <AddBank />,
  // },
  {
    key: "add-tax",
    route: "/master/tax",
    component: <AddTax />,
  },
  // {
  //   key: "add-reason",
  //   route: "/master/reason",
  //   component: <AddReason />,
  // },
  // {
  //   key: "add-currency",
  //   route: "/master/currency",
  //   component: <AddCurrency />,
  // },
  // {
  //   key: "add-warehouse",
  //   route: "/master/warehouse",
  //   component: <AddWarehouse />,
  // },
  // {
  //   key: "add-country",
  //   route: "/master/country",
  //   component: <AddCountry />,
  // },
  // {
  //   key: "add-region",
  //   route: "/master/region",
  //   component: <AddRegion />,
  // },
  // {
  //   key: "add-branch",
  //   route: "/master/branch",
  //   component: <AddBranch />,
  // },
  // {
  //   key: "add-van",
  //   route: "/master/van",
  //   component: <AddVanMaster />,
  // },
  // {
  //   key: "add-route",
  //   route: "/master/route",
  //   component: <AddRoute />,
  // },
  // {
  //   key: "add-outlet-product",
  //   route: "/master/outlet-product",
  //   component: <AddOutlet_product />,
  // },
  // {
  //   key: "add-item-group",
  //   route: "/master/item-group",
  //   component: <AddItemGroup />,
  // },
  {
    key: "add-item-uom",
    route: "/master/item-uom",
    component: <AddItemUOM />,
  },
  // {
  //   key: "add-listing-fee",
  //   route: "/master/listing-fee",
  //   component: <AddListing_Fee />,
  // },
  // {
  //   key: "add-shelf-rent",
  //   route: "/master/shelf-rent",
  //   component: <AddShelf_rent />,
  // },
  // {
  //   key: "add-invite-user",
  //   route: "/master/users",
  //   component: <Invite_user />,
  // },
  // {
  //   key: "route-item-group",
  //   route: "/master/route-item-group",
  //   component: <AddRoute_item_group />,
  // },
  {
    key: "portfolio",
    route: "/master/portfolio-management",
    component: <AddPortfolio_management />,
  },
  // {
  //   key: "expense",
  //   route: "/master/expense",
  //   component: <Add_Expense />,
  // },
  // {
  //   key: "target-comission",
  //   route: "/master/target-comission",
  //   component: <Add_Target_Commission />,
  // },
  {
    key: "item",
    route: "/master/item",
    component: <Add_Item />,
  },
  {
    key: "ItemFamMst",
    route: "/master/ItemFamMst",
    component: <Add_ItemFamDep />,
  },
  {
    key: "itemcate",
    route: "/master/itemcate",
    component: <Add_Itemcate />,
  },
  {
    key: "itemcolor",
    route: "/master/itemcolor",
    component: <Add_Itemcolor />,
  },
  {
    key: "itemdepart",
    route: "/master/ItemDeptMst",
    component: <Add_Itemdepart />,
  },
  {
    key: "sizemaster",
    route: "/master/sizemaster",
    component: <Add_Sizemaster />,
  },
  {
    key: "change-password",
    route: "/change-password",
    component: <Change_Password />,
  },
  {
    key: "supplier",
    route: "/master/supplier",
    component: <Add_supplier />,
  },
 
  // {
  //   key: "journey-plan",
  //   route: "/master/journey-plan/add",
  //   component: <Add_Journey_plans />,
  // },
  {
    key: "salesman",
    route: "/master/salesman",
    component: <Add_Salesman />,
  },
  // {
  //   key: "promotion",
  //   route: "/master/promotion",
  //   component: <Add_Promotion />,
  // },
  // {
  //   key: "pricing",
  //   route: "/master/pricing",
  //   component: <Add_Pricing />,
  // },
  // {
  //   key: "discount",
  //   route: "/master/discount",
  //   component: <Add_Discount />,
  // },
  // {
  //   key: "rebate",
  //   route: "/master/rebate/add",
  //   component: <Add_Rebate />,
  // },
  // {
  //   key: "salesman-load",
  //   route: "/master/salesman-load/add",
  //   component: <Add_Salesman_load />,
  // },
  // {
  //   key: "load-request",
  //   route: "/master/load-request/add",
  //   component: <Add_load_Request />,
  // },
  // {
  //   key: "pdc",
  //   route: "/master/pdc/add",
  //   component: <Add_PDC />,
  // },
  // {
  //   key: "collection",
  //   route: "/master/collection/add",
  //   component: <Add_Collections />,
  // },
  {
    key: "grn",
    route: "/master/grn/add",
    component: <Add_GRN />,
  },
  // {
  //   key: "cashier-reciept",
  //   route: "/master/cashier-reciept/add",
  //   component: <Add_Cashier_Reciept />,
  // },
  // {
  //   key: "credit-note",
  //   route: "/master/credit-note/add",
  //   component: <Add_Credit_Notes />,
  // },
  {
    key: "invoice",
    route: "/master/invoice/add",
    component: <Add_Invoice />,
  },
  {
    key: "delivery",
    route: "/master/delivery/add",
    component: <Add_Delivery />,
  },
  {
    key: "order",
    route: "/master/order/add",
    component: <Add_Orders />,
  },
  {
    key: "purchaseorder",
    route: "/master/purchaseorder/add",
    component: <Add_Po />,
  },
  {
    key: "debit-note",
    route: "/master/debit-note/add",
    component: <Add_Debit_Notes />,
  },
  {
    key: "add-role",
    route: "/master/role",
    component: <AddRole />,
  },
  {
    key: "add-itemBrandMst",
    route: "/master/itemBrandMst",
    component: <Add_ItemBrandMst />,
  },
  {
    key: "edit-ItemSFamMst",
    route: "/ItemSFamMst/edit/:id",
    component: <Edit_ItemSFamMst />,
  },
  {
    key: "add-ItemSFamMst",
    route: "/master/ItemSFamMst",
    component: <Add_ItemSFamMst />,
  },

  {
    key: "add-workflow",
    route: "/master/workflow",
    component: <Add_Workflow />,
  },
  {
    key: "add-activity-profile",
    route: "/master/add-activity-profile",
    component: <Add_Activity />,
  },

  {
    key: "van-to-van-transfer",
    route: "/master/van-to-van-transfer/add",
    component: <Add_VanToVan_Transfer />,
  },
  {
    key: "add-jd-version-config",
    route: "/master/jd-version-config",
    component: <Add_JDE_Config />,
  },

  //Edit files route
  {
    key: "edit-customer",
    route: "/customer/edit/:id",
    component: <EditNew />,
  },
  {
    key: "edit-ItemBrandMst",
    route: "/ItemBrandMst/edit/:id",
    component: <EditItemBrandMst />,
  },
  {
    key: "edit-item",
    route: "/item/edit/:id",
    component: <Edit_Item />,
  },

  {
    key: "edit-itemDeptMst",
    route: "/itemDeptMst/edit/:id",
    component: <Edit_itemDeptMst />,
  },
  {
    key: "edit-itemcolor",
    route: "/itemcolor/edit/:id",
    component: <Edit_Itemcolor />,
  },
  {
    key: "edit-itemcate",
    route: "/itemcate/edit/:id",
    component: <Edit_itemcate />,
  },
  {
    key: "edit-sizemaster",
    route: "/sizemaster/edit/:id",
    component: <Edit_Sizemaster />,
  },
  {
    key: "edit-supplier",
    route: "/supplier/edit/:id",
    component: <Edit_supplier />,
  },
  {
    key: "edit-ItemFamMst",
    route: "/ItemFamMst/edit/:id",
    component: <EditItemFm />,
  },
  {
    key: "edit-salesman",
    route: "/salesman/edit/:id",
    component: <Edit_Salesman />,
  },
  {
    key: "edit-journey-plans",
    route: "/journey-plans/edit/:id",
    component: <Edit_Journey_plans />,
  },
  {
    key: "sdsdsd",
    route: "/table/:id",
    component: <Table />,
  },
  {
    key: "edit-promotion",
    route: "/promotion/edit/:id",
    component: <Edit_Promotion />,
  },
  {
    key: "edit-promotion",
    route: "/invoice/edit/:id",
    component: <Edit_Invoice />,
  },
  {
    key: "edit-pricing",
    route: "/pricing/edit/:id",
    component: <Edit_Pricing />,
  },
  {
    key: "edit-discount",
    route: "/discount/edit/:id",
    component: <Edit_Discount />,
  },
  {
    key: "edit-order",
    route: "/order/edit/:id",
    component: <Edit_Orders />,
  },
  {
    key: "edit-purchaseorder",
    route: "/purchaseorder/edit/:id",
    component: <Edit_Po />,
  },
  {
    key: "view-order",
    route: "/order/view/:id",
    component: <View_Orders />,
  },
  {
    key: "view-purchaseorder",
    route: "/purchaseorder/view/:id",
    component: <View_Orders_po />,
  },
  {
    key: "generate-invoice",
    route: "/order/generate_invoice/:id",
    component: <Generate_Invoice />,
  },
  {
    key: "generate-invoice-po",
    route: "/purchaseorder/generate_invoice-po/:id",
    component: <Generate_Invoice_po />,
  },
  {
    key: "edit-delivery",
    route: "/delivery/edit/:id",
    component: <Edit_Delivery />,
  },
  {
    key: "edit-credit-note",
    route: "/credit-note/edit/:id",
    component: <Edit_Credit_Notes />,
  },
  {
    key: "edit-debit-note",
    route: "/debit-note/edit/:id",
    component: <Edit_Debit_Notes />,
  },
  {
    key: "edit-route-item-group",
    route: "/route-item-group/edit/:id",
    component: <EditRoute_item_group />,
  },
  {
    key: "edit-portfolio",
    route: "/portfolio/edit/:id",
    component: <EditPortfolio_management />,
  },
  {
    key: "edit-load-request",
    route: "/load-request/edit/:id",
    component: <Edit_load_Request />,
  },
  {
    key: "edit-grn",
    route: "/grn/edit/:id",
    component: <Edit_GRN />,
  },
  {
    key: "edit-pdc",
    route: "/pdc/edit/:id",
    component: <Edit_PDC />,
  },
  {
    key: "edit-target",
    route: "/target/edit",
    component: <Edit_Target_Commission />,
  },
  {
    key: "edit-tax",
    route: "/master/tax/edit/:id",
    component: <EditTax />,
  },
  {
    key: "edit-reason",
    route: "/master/reason/edit/:id",
    component: <EditReason />,
  },
  {
    key: "edit-bank",
    route: "/master/bank/edit/:id",
    component: <EditBank />,
  },
  {
    key: "edit-currency",
    route: "/master/currency/edit/:id",
    component: <EditCurrency />,
  },
  {
    key: "edit-ItemFamMst",
    route: "/master/itemFamMst/edit/:id",
    component: <EditCurrency />,
  },
  {
    key: "edit-warehouse",
    route: "/master/warehouse/edit/:id",
    component: <EditWarehouse />,
  },
  {
    key: "edit-country",
    route: "/master/country/edit/:id",
    component: <EditCountry />,
  },
  {
    key: "edit-region",
    route: "/master/region/edit/:id",
    component: <EditRegion />,
  },
  {
    key: "edit-branch",
    route: "/master/branch/edit/:id",
    component: <EditBranch />,
  },
  {
    key: "edit-van",
    route: "/master/van/edit/:id",
    component: <EditVanMaster />,
  },
  {
    key: "edit-route",
    route: "/master/route/edit/:id",
    component: <EditRoute />,
  },
  {
    key: "edit-outlet",
    route: "/master/outlet/edit",
    component: <EditOutlet_product />,
  },
  {
    key: "edit-item-group",
    route: "/master/item-group/edit/:id",
    component: <EditItemGroup />,
  },
  {
    key: "edit-item-uom",
    route: "/master/item-uom/edit/:id",
    component: <EditItemUOM />,
  },
  {
    key: "edit-van-to-van-transfer",
    route: "/van-to-van-transfer/edit/:id",
    component: <Edit_VanToVan_Transfer />,
  },
  {
    key: "edit-jd-version-config",
    route: "/master/jd-config",
    component: <Edit_JDE_Config />,
  },
];

export const routesUser = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Order",
    key: "order",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/order",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customer",
    component: <Customer />,
  },
  {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <Icon fontSize="small">text_snippet</Icon>,
    route: "/invoice",
    component: <Invoice />,
  },
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/portfolio-management",
    component: <Portfolio_Management />,
  },
  {
    // type: "collapse",
    // name: "Sign In",
    key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    // name: "Sign Up",
    key: "sign-up",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/signup",
    component: <SignUp />,
  },
  {
    route: "/auth/forget",
    component: <Forget />,
  },

  //Add new pages route
  {
    key: "add-new",
    route: "/master/customer",
    component: <Addnew />,
  },
  {
    key: "portfolio",
    route: "/master/portfolio-management",
    component: <AddPortfolio_management />,
  },
  {
    key: "invoice",
    route: "/master/invoice/add",
    component: <Add_Invoice />,
  },
  {
    key: "order",
    route: "/master/order/add",
    component: <Add_Orders />,
  },

  //Edit files route
  {
    key: "edit-customer",
    route: "/customer/edit/:id",
    component: <EditNew />,
  },

  {
    key: "edit-order",
    route: "/order/edit/:id",
    component: <Edit_Orders />,
  },
  {
    key: "view-order",
    route: "/order/view/:id",
    component: <View_Orders />,
  },
  {
    key: "generate-invoice",
    route: "/order/generate_invoice/:id",
    component: <Generate_Invoice />,
  },
  {
    key: "edit-portfolio",
    route: "/portfolio/edit/:id",
    component: <EditPortfolio_management />,
  },
];

