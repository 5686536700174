import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import axios, { axios_get, axios_post } from "../../../axios";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete, TextField } from "@mui/material";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from "@mui/x-data-grid";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import routes from "routes";
import { ToastMassage } from '../../../toast';


const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    ".MuiFormControlLabel-label":
      checked &&
      {
        //   color: theme.palette.primary.main,
      },
  })
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

function Add_GRN() {
  const [formError, setFormError] = useState({});
  const [itemError, setItemError] = useState("");
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState([]);
  const [sources, setSourceList] = useState([]);
  const [destinations, setdestiList] = useState([]);
  const [isSubmit, setisSubmit] = useState(false);

  const [formData, setFormData] = useState({
    grn_number: "",
    grn_date: "",
    grn_remark: "",
  });
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "#", width: 100, sortable: false, disableColumnMenu: true, },
    {
      field: "item_code",
      headerName: "ITEM CODE",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={item}
          getOptionLabel={(option) => option.item_code || ''}
          renderOption={(props, option) => (
            <li {...props}>{option.item_code}-{option.item_name}</li>
          )}
          style={{ height: 45 }}
          sx={{ width: 190 }}
          value={params.row.newValue}
          onChange={(event, newValue) => ItemSelect(newValue, params)}
          renderInput={(params) => (
            <TextField {...params} label="Item" variant="outlined" />
          )}
        />
      ),
    },
    {
      field: "item_name",
      headerName: "ITEM NAME",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <MDInput
          type="text"
          // label="Item name"
          variant="outlined"
          value={params.row.item_name}
          disabled={true}
        // sx={{ width: 200 }}
        />
      ),
    },
    {
      field: "uom",
      headerName: "UOM",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <MDInput
          type="text"
          // label="uom"
          variant="outlined"
          value={params.row.uom}
          disabled={true}
        // sx={{ width: 200 }}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <MDInput
          type="number"
          // label="Quantity"
          variant="outlined"
          value={params.row.qty}
          onChange={(value) => itemquantityChange(value, params)}
        // sx={{ width: 200 }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "ACTION",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <MDButton
          variant="outlined"
          color="info"
          iconOnly
          onClick={() => handleRemoveRow(params.rowIndex)}
        >
          <Icon fontSize="small">clear</Icon>
        </MDButton>
      ),
    },
  ];
  const handleAddRow = () => {
    setRows(prevRows => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        item_code: "",
        item_name: "",
        uom: "",
        qty: "",
        reason: "",
        action: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const ItemSelect = (newValue, params) => {
    const updatedRows = rows.map((row, index) =>
      row.id === params.id
        ? {
          ...row,
          item_id: newValue?.id,
          item_code: newValue?.item_code,
          item_name: newValue?.item_name,
          qty: 1,
          newValue: newValue,
        }
        : row
    );
    setRows(updatedRows);
  }
  const itemquantityChange = (qty, params) => {
    const { value } = qty.target
    let totalquantity = value;
    let itemPrice = params.row.price;
    let itemNet = itemPrice * value;
    let itemTotal = itemNet;
    const updatedRows = rows.map((row, index) =>
      row.id === params.id
        ? {
          ...row,
          qty: value,
        }
        : row
    );
    setRows(updatedRows);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validation = (formData) => {
    let errors = {};
    if (!formData.grn_number) {
      errors.grn_number = "Grn Number is required";
    }

    if (!formData.grn_date) {
      errors.grn_date = "Grn date is required";
    }


    return errors;
  };

  const handleSubmit = async (event) => {
    setisSubmit(true);
    event.preventDefault();
    let errors = validation(formData);

    if (Object.keys(errors).length > 0) {
      setisSubmit(false);
      setFormError(errors);
    }
    else {
      if (rows.length == 0) {
        setisSubmit(false);
        setFormError({});
        setItemError('Please select item');
        ToastMassage('Please select item', 'error')
        // console.log("formData", formData);
      } else {
        setisSubmit(false);
        setFormError({});

        let finalPramas = {
          ...formData,
          // discount: sums.discount,
          // net: sums.net,
          // excise: sums.excise,
          // vat: sums.vat,
          // total: sums.total,
          items: rows
        }
        console.log("finalPramas", finalPramas);

        const response = await axios_post(true, "grn/add", finalPramas);
        if (response) {
          if (response.status) {
            ToastMassage(response.message, 'success')
            navigate("/grn");
          } else {
            ToastMassage(response.message, 'error')
          }
        }

        // Submit your form data here
      }

    }
  };


  const ItemList = async () => {
    const response = await axios_post(true, "item/list");
    if (response && response.status && Array.isArray(response.data)) {
      setItem(response.data);
    } else {
      ToastMassage(response.message, 'error')
    }
  }

  const OrderNuberRange = async () => {
    let params = {
      function_for: "goodreceiptnote"
    }
    const response = await axios_post(true, "code_setting/get-next-comming-code", params);
    if (response) {
      if (response.status) {
        setFormData((prevData) => ({
          ...prevData,
          "grn_number": response.data.number_is,
        }));
      } else {
        ToastMassage(response.message, 'error')
      }
    }
  }
  useEffect(() => {
    OrderNuberRange();
    ItemList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12}>
            <Card component="form" role="form" onSubmit={handleSubmit}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={1}>
                  <Grid item xs={3} mr={35}>
                    <MDTypography variant="h6" color="white">
                      <Icon fontSize="small">sticky_note_2</Icon>
                      Add Goods Receipt Note
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} ml={35}>
                    <MDTypography component={Link} to="/grn">
                      <MDButton variant="gradient" color="light">
                        &#x3c;&nbsp;Back
                      </MDButton>
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                    <Grid item xs={6}>
                      <MDBox pb={2}>
                        <InputLabel sx={{ mb: 1 }}>GRN Number</InputLabel>
                        <MDInput
                          type="text"
                          variant="outlined"
                          name="grn_number"
                          value={formData.grn_number}
                          onChange={handleChange}
                          sx={{ width: 300 }}
                        />
                        {formError.grn_number && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.grn_number}</MDTypography>}

                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox pb={2}>
                        <InputLabel sx={{ mb: 1 }}>GRN Date</InputLabel>
                        <MDInput
                          type="date"
                          variant="outlined"
                          name="grn_date"
                          value={formData.grn_date}
                          onChange={(e) => setFormData({ ...formData, grn_date: e.target.value })}
                          sx={{ width: 300 }}
                        />
                        {formError.grn_date && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }} F>{formError.grn_date}</MDTypography>}

                      </MDBox>
                    </Grid>
                    <Grid item xs={12} pb={6}>
                      <DataGrid disableColumnFilter={false} disableColumnMenu={false} hideFooterPagination={false} hideFooter={false} hideFooterSelectedRowCount={false} rowsPerPageOptions={false} isRowSelectable={false} rows={rows} columns={columns} disableRowSelectionOnClick />
                      <MDButton variant="contained" color="secondary" onClick={handleAddRow}>
                        Add Row
                      </MDButton>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox pb={2}>
                        <InputLabel sx={{ mb: 1 }}>GRN Remark</InputLabel>
                        <MDInput
                          type="text"
                          variant="outlined"
                          name="grn_remark"
                          value={formData.grn_remark}
                          onChange={(e) => setFormData({ ...formData, grn_remark: e.target.value })}
                          sx={{ width: 500 }}
                        />

                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Grid container spacing={2} justifyContent="right" sx={{ mt: 0, mb: 1 }}>
                <Grid item xs={1} ml={1}>
                  <MDBox>
                    <MDButton variant="gradient" color="info" type="submit" fullWidth>
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Add_GRN;
