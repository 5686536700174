import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Autocomplete, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
// import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from "@mui/x-data-grid";
import DataTable from "examples/Tables/DataTable";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import routes from "routes";
import { axios_get, axios_post } from '../../../axios';
import { ToastMassage } from '../../../toast';

const payment_term = [
    { label: "Cash", value: "1" },
    { label: "BILL TO BILL PAYMENT AR", value: "2" },
    { label: "Net 90 Days", value: "3" },
    { label: "NET 30 DAYS", value: "4" },
    { label: "Net 60 Days", value: "5" },
    { label: "Cash on Delivery", value: "6" },
    { label: "Net 45 Days", value: "7" },
];

function Add_Po() {
    const navigate = useNavigate();
    const [formError, setFormError] = useState({});
    const [itemError, setItemError] = useState("");
    const [rows, setRows] = useState([]);
    const [autocompleteValue, setAutocompleteValue] = useState("");
    const [autocompleteSalesmanValue, setAutocompleteSalesmanValue] = useState("");
    const [autocompletePaymentValue, setAutocompletePaymentValue] = useState("");
    const [item, setItem] = useState([]);
    const [Customers, setCustomerList] = useState([]);
    const [Salesmans, setSalesmanList] = useState([]);
    const [isSubmit, setisSubmit] = useState(false);
    const [formData, setFormData] = useState({
        customer_id: "",
        salesman_id: "",
        customer_lpo: "",
        order_number: "",
        delivery_date: "",
        payment_terms: "",
        status: "Open",
        type: "purchase order",
    });
    const [selectedCustomer, setSelectedCustomer] = useState(null);


    useEffect(() => {
        ItemList();
        CustomerList();
        SalesmanList();
        OrderNuberRange();
    }, []);

    const ItemList = async () => {
        const response = await axios_post(true, "item/list");
        if (response) {
            if (response.status) {
                setItem(response.data);
            } else {
                ToastMassage(response.message, 'error')
            }
        }
    }


    const CustomerList = async () => {
        const response = await axios_post(true, "supplier/list");
        if (response) {
            if (response.status) {
                setCustomerList(response.data.records);
            } else {
                ToastMassage(response.message, 'error')
            }
        }
    }
    let user_data = JSON.parse(localStorage.getItem("user_data"));

    const SalesmanList = async () => {
        const response = await axios_post(true, "salesman/list", user_data.usertype === 3 ? { id: user_data.id } : {});
        if (response) {
            if (response.status) {
                setSalesmanList(response.data.records);
            } else {
                ToastMassage(response.message, 'error')
            }
        }
    }

    const OrderNuberRange = async () => {
        let params = {
            function_for: "purchase_order"
        }
        const response = await axios_post(true, "code_setting/get-next-comming-code", params);
        if (response) {
            if (response.status) {
                setFormData((prevData) => ({
                    ...prevData,
                    "order_number": response.data.number_is,
                }));
            } else {
                ToastMassage(response.message, 'error')
            }
        }
    }

    const handleAutocompleteChange = (event, newValue, type) => {
        // console.log('newValue',newValue)
        if (type == 'customer') {
            setAutocompleteValue(newValue);
            setSelectedCustomer(newValue);
            setFormData((prevData) => ({
                ...prevData,
                "customer_id": newValue == null ? "" : newValue?.user_id,
            }));
        } else if (type == 'salesman') {
            setAutocompleteSalesmanValue(newValue);
            setFormData((prevData) => ({
                ...prevData,
                "salesman_id": newValue == null ? "" : newValue?.user_id,
            }));
        } else if (type == "payment_term") {
            setAutocompletePaymentValue(newValue);
            setFormData((prevData) => ({
                ...prevData,
                "payment_terms": newValue == null ? "" : newValue?.value,
            }));
        }
    };


    const handleAddRow = () => {
        setRows([
            ...rows,
            {
                id: rows.length + 1,
                item_id: "",
                item_code: "",
                item_name: "",
                uom: "",
                quantity: 0.00.toFixed(2),
                skim: 'None',

                sunit: "",
                hsn_code: "",
                receiving_site: "",
                expiry_delivery_date: "",
                currency: "INR",
                itemtype: "Finished Goods",
                landed_cost_per_unit: "",
                purchase_cost_per_unit: "",

                price: 0.00.toFixed(2),
                excise: 0.00.toFixed(2),
                discount: 0.00.toFixed(2),
                net: 0.00.toFixed(2),
                vat: 0,
                ptr_di: "",
                taxa_ble: "",
                cgst: "",
                cgst_amount: "",
                sgst: "",
                sgst_amount: "",
                igst: "",
                igst_amount: "",
                total: 0.00.toFixed(2),
                actions: "",
                newValue: "",
                uom_list: [],
                originalPrice: 0.00,
                originalDiscount: 0.00,
                originalNet: 0.00,
                originalVat: 0,
                originalTotal: 0.00
            },
        ]);
    };


    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const ItemSelect = (newValue, params) => {
        const updelivery_datedRows = rows.map((row) =>
            row.id === params.id
                ? {
                    ...row,
                    item_id: newValue?.id,
                    item_code: newValue?.item_code,
                    item_name: newValue?.item_name,
                    uom: newValue?.item_main_prices?.[0].item_uom?.id,
                    item_uom: newValue?.item_main_prices?.[0].item_uom?.name,
                    quantity: 1.00.toFixed(2),
                    uom_list: newValue?.item_main_prices,
                    newValue: newValue,
                    price: parseFloat(newValue?.item_vat_percentage).toFixed(2),
                    vat: (parseFloat(newValue?.item_tax)),
                    taxa_ble: ((parseFloat(newValue?.item_vat_percentage) * 1) * parseFloat(newValue?.item_tax) / 100
                    ).toFixed(2),
                    total: (parseFloat(newValue?.item_vat_percentage) +
                        (parseFloat(newValue?.item_vat_percentage) *
                            parseFloat(newValue?.item_tax) / 100)).toFixed(2),
                    net: parseFloat(parseFloat(newValue?.item_vat_percentage) * 1).toFixed(2),
                }
                : row
        );
        setRows(updelivery_datedRows);
    };

    const ItemSelectUom = (newValue, params) => {
        const updelivery_datedRows = rows.map((row) =>
            row.id === params.id
                ? {
                    ...row,
                    uom: newValue?.item_uom?.id,
                    newValue_uom: newValue,
                }
                : row
        );
        setRows(updelivery_datedRows);
    }

    const itemquantityChange = (eventOrQuantity, params) => {
        const { name, value } = eventOrQuantity?.target || { name: null, value: null };

        if (!name || value === undefined) {
            return;
        }

        let itemPrice = parseFloat(params.price);

        if (name === 'quantity') {
            const totalquantity = value;
            const itemNet = itemPrice * totalquantity;
            const itemDiscount = parseFloat(params.discount);

            if (itemDiscount > itemNet) {
                ToastMassage('Discount can not be more than price.');
                return;
            }

            const itemTotal = itemNet - itemDiscount;
            const taxa_ble = ((itemPrice * totalquantity) * (parseFloat(params.vat) / 100)).toFixed(2);

            const updelivery_datedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        quantity: totalquantity,
                        price: itemPrice,
                        discount: itemDiscount,
                        net: parseFloat(itemNet),
                        total: (itemTotal + parseFloat(taxa_ble)).toFixed(2),
                        taxa_ble: taxa_ble,
                    }
                    : row
            );
            setRows(updelivery_datedRows);
        } else if (name === 'price') {
            itemPrice = value;
            const totalquantity = params.quantity;
            const itemNet = itemPrice * totalquantity;
            const itemDiscount = parseFloat(params.discount);
            if (itemDiscount > itemNet) {
                ToastMassage('Discount can not be more than price.');
                return;
            }
            const itemTotal = itemNet - itemDiscount;
            const taxa_ble = ((itemPrice * totalquantity) * (parseFloat(params.vat) / 100)).toFixed(2);

            const updelivery_datedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        quantity: totalquantity,
                        vat: parseFloat(params.vat),
                        taxa_ble: taxa_ble,
                        total: (itemTotal + parseFloat(taxa_ble)).toFixed(2),
                        price: itemPrice,
                        discount: itemDiscount,
                        net: itemNet,
                    }
                    : row
            );
            setRows(updelivery_datedRows);
        } else if (name === 'discount') {
            const totalquantity = params.quantity;
            const itemNet = itemPrice * totalquantity;
            const itemDiscount = value;
            const itemTotal = itemNet - itemDiscount;
            if (itemDiscount > itemNet) {
                ToastMassage('Discount can not be more than price.');
                return;
            }
            const taxa_ble = ((itemPrice * totalquantity) * (parseFloat(params.vat) / 100)).toFixed(2);

            const updelivery_datedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        quantity: parseFloat(totalquantity).toFixed(2),
                        vat: (parseFloat(row?.vat)),
                        taxa_ble: taxa_ble,
                        total: (itemTotal + parseFloat(taxa_ble)).toFixed(2),
                        price: parseFloat(itemPrice).toFixed(2),
                        discount: itemDiscount,
                        net: parseFloat(itemNet).toFixed(2),
                    }
                    : row
            );
            setRows(updelivery_datedRows);
        }
        else if (name === 'purchase_cost_per_unit') {
            const updatedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        purchase_cost_per_unit: value,
                    }
                    : row
            );
            setRows(updatedRows);
        }
        else if (name === 'landed_cost_per_unit') {
            const updatedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        landed_cost_per_unit: parseFloat(value),
                    }
                    : row
            );
            setRows(updatedRows);
        }
        else if (name === 'hsn_code') {
            const updatedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        hsn_code: value,
                    }
                    : row
            );
            setRows(updatedRows);
        }
        else if (name === 'receiving_site') {
            const updatedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        receiving_site: value,
                    }
                    : row
            );
            setRows(updatedRows);
        }
        else if (name === 'expiry_delivery_date') {
            const updatedRows = rows.map((row) =>
                row.id === params.id
                    ? {
                        ...row,
                        expiry_delivery_date: value,
                    }
                    : row
            );
            setRows(updatedRows);
        }

    };

    const handleSkimChange = (value, rowIndex) => {
        setRows(prevRows =>
            prevRows.map((row, index) =>
                index === rowIndex
                    ? {
                        ...row,
                        skim: value,
                        ...(value === 'Free' ? { price: 0, discount: 0, net: 0, vat: 0, total: 0, taxa_ble: 0 } : {
                            price: parseFloat(row.newValue?.item_vat_percentage).toFixed(2),
                            vat: (parseFloat(row.newValue?.item_tax)),
                            taxa_ble: ((parseFloat(row.newValue?.item_vat_percentage) * 1) * parseFloat(row.newValue?.item_tax) / 100
                            ).toFixed(2),
                            total: (parseFloat(row.newValue?.item_vat_percentage) +
                                (parseFloat(row.newValue?.item_vat_percentage) *
                                    parseFloat(row.newValue?.item_tax) / 100)).toFixed(2),
                            net: (parseFloat(row.newValue?.item_vat_percentage) * 1).toFixed(2),
                        }),
                    }
                    : row
            )

        );
    };
    const handleItemTypeChange = (value, rowIndex, name) => {

        if (name === 'itemtype') {

            setRows(prevRows =>
                prevRows.map((row, index) =>
                    index === rowIndex
                        ? {
                            ...row,
                            itemtype: value,
                        }
                        : row
                )
            );
        }
        else if (name === 'currency') {
            setRows(prevRows =>
                prevRows.map((row, index) =>
                    index === rowIndex
                        ? {
                            ...row,
                            currency: value,
                        }
                        : row
                )
            );
        }

    };



    const validation = (formData) => {
        let errors = {};

        if (!formData.customer_id) {
            errors.customer = "Supplier is required";
        }
        // if (!formData.status) {
        //     errors.status = "Status is required";
        // }

        if (!formData.order_number) {
            errors.order_number = "PO Number is required";
        }

        if (!formData.salesman_id) {
            errors.salesman = "Employee is required";
        }

        if (!formData.delivery_date) {
            errors.delivery_date = "Date is required";
        }

        if (!formData.payment_terms) {
            errors.payment_term = "Payment Terms are required";
        }
        if (!formData.customer_lpo) {
            errors.customer_lpo = "Supplier lpo is required";
        }

        return errors;
    };

    const handleSubmit = async (event) => {
        setisSubmit(true);
        event.preventDefault();
        let errors = validation(formData);
        let invalidRow = rows.some(row =>
            row.skim === 'None' && (!row.quantity || !row.price || row.quantity <= 0 || row.price <= 0)
        );
        console.log("rows", rows)

        if (invalidRow) {
            setisSubmit(false);
            // setFormError({ general: "Quantity and Price cannot be null or zero." });
            ToastMassage('Quantity and Price cannot be null or zero.', 'error');
            return;
        }
        if (Object.keys(errors).length > 0) {
            setisSubmit(false);
            setFormError(errors);
        } else {
            if (rows.length == 0) {
                setisSubmit(false);
                setFormError({});
                setItemError('Please select item');
                ToastMassage('Please select item', 'error')
                // console.log("formData", formData);
            } else {
                setFormError({});

                let finalPramas = {
                    ...formData,
                    discount: sums.discount,
                    net: sums.net,
                    excise: sums.excise,
                    vat: sums.vat,
                    total: sums.total,
                    items: rows
                }

                const response = await axios_post(true, "purchase_order/store", finalPramas);
                if (response) {
                    setisSubmit(false);
                    if (response.status) {
                        ToastMassage(response.message, 'success')
                        navigate("/purchaseorder");

                    } else {
                        ToastMassage(response.message, 'error')
                    }
                }
            }

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const calculateSums = (items) => {
        return items.reduce((sums, item) => {

            sums.excise += parseFloat(item.excise) || 0.00;
            sums.discount += parseFloat(item.discount) || 0.00;
            sums.net += parseFloat(item.net) || 0.00;
            sums.vat += parseFloat(item?.taxa_ble) || 0.00;
            sums.total += parseFloat(item.total) || 0.00;
            return sums;
        }, { excise: 0.00, discount: 0.00, net: 0.00, vat: 0.00, total: 0.00 });
    };
    const sums = calculateSums(rows);
    const handleBack = () => {
        navigate("/purchaseorder");
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox className="custome-card" pt={6} pb={3}>
                <Grid container spacing={6} justifyContent="center">
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit} method="POST" action="#">
                            <Card >
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <Grid container xs={12} spacing={0}>
                                        <Grid item xs={6} mr={0}>
                                            <MDTypography variant="h6" color="white" sx={{ width: 165 }}>
                                                <Icon fontSize="small">shopping_cart</Icon>
                                                Add Purchase order
                                            </MDTypography>
                                        </Grid>

                                        <Grid item xs={6} ml={0}>
                                            <MDTypography component={Link} to="/purchaseorder">
                                                <MDButton variant="gradient" color="light">
                                                    Back
                                                </MDButton>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox>
                                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                                            <Grid item xs={12} sm={4}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Supplier</InputLabel>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={Customers}
                                                        getOptionLabel={(option) => option.customer_code || ''}
                                                        renderOption={(props, option) => (
                                                            <li {...props}>{option.customer_code}-{option?.users?.firstname} {option?.users?.lastname}</li>
                                                        )}
                                                        value={autocompleteValue}
                                                        onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'customer')}
                                                        // sx={{ height: 20 }}
                                                        // sx={{ width: 300 }}
                                                        className="small-autocomplete"
                                                        renderInput={(params) => <TextField {...params} className="small-input" />}
                                                    ></Autocomplete>
                                                    {formError.customer && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.customer}</MDTypography>}
                                                    {selectedCustomer && (
                                                        <MDBox mt={2}>
                                                            {/* <MDTypography>Trade License No: {selectedCustomer.trade_license_no}</MDTypography> */}
                                                            <MDTypography fontSize="small">TAX No: {selectedCustomer.users?.custax1}</MDTypography>
                                                            <MDTypography fontSize="small">Address: {selectedCustomer?.customer_address_1}</MDTypography>
                                                            <MDTypography fontSize="small">Contact Person: {selectedCustomer?.users?.firstname}</MDTypography>
                                                            <MDTypography fontSize="small">Contact Number: {selectedCustomer.users?.mobile}</MDTypography>
                                                            <MDTypography fontSize="small">Email: {selectedCustomer.users?.email}</MDTypography>
                                                        </MDBox>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>PO Number</InputLabel>
                                                    <MDInput
                                                        type="text"
                                                        // label="Order Number"
                                                        variant="outlined"
                                                        name="order_number"
                                                        value={formData.order_number}
                                                        onChange={handleChange}
                                                        disabled={true}
                                                        // sx={{ width: 300 }}
                                                        className="small-input"
                                                    />
                                                    {formError.order_number && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.order_number}</MDTypography>}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Date</InputLabel>
                                                    <MDInput
                                                        type="date"
                                                        variant="outlined"
                                                        sx={{ width: 300 }}
                                                        value={formData.delivery_date}
                                                        className="small-input"
                                                        onChange={(e) => setFormData({ ...formData, delivery_date: e.target.value })}
                                                    />
                                                    {formError.delivery_date && (
                                                        <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.delivery_date}</MDTypography>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <InputLabel sx={{ mb: 1 }}>Employee</InputLabel>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={Salesmans}
                                                    getOptionLabel={(option) => option.salesman_code || ''}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>{option.salesman_code}-{option?.users?.firstname}</li>
                                                    )}
                                                    value={autocompleteSalesmanValue}
                                                    className="small-autocomplete"
                                                    onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'salesman')}
                                                    sx={{ width: 300 }}
                                                    renderInput={(params) => <TextField {...params} className="small-input" />}
                                                ></Autocomplete>
                                                {formError.salesman && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.salesman}</MDTypography>}

                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <InputLabel sx={{ mb: 1 }}>Payment Terms</InputLabel>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={payment_term}
                                                    value={autocompletePaymentValue}
                                                    onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'payment_term')}
                                                    // style={{ height: 45 }}
                                                    sx={{ width: 300 }}
                                                    className="small-autocomplete"
                                                    renderInput={(params) => <TextField {...params} className="small-input" />}
                                                ></Autocomplete>
                                                {formError.payment_term && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.payment_term}</MDTypography>}
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Supplier LPO</InputLabel>
                                                    <MDInput
                                                        type="text"
                                                        variant="outlined"
                                                        sx={{ width: 300 }}
                                                        name="customer_lpo"
                                                        value={formData.customer_lpo}
                                                        onChange={handleChange}
                                                        className="small-input"
                                                    />
                                                    {formError.customer_lpo && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.customer_lpo}</MDTypography>}

                                                </MDBox>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Status</InputLabel>
                                                    <Autocomplete
                                                        options={["Open", "Close", "Partial receive", 'Cancel']}
                                                        value={formData.status}
                                                        onChange={(event, newValue) => setFormData({ ...formData, status: newValue })}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                        sx={{ width: 300 }}
                                                    />
                                                    {formError.status && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.status}</MDTypography>}

                                                </MDBox>
                                            </Grid> */}
                                            <Grid item xs={12} sm={4}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Status</InputLabel>
                                                    <Autocomplete
                                                        options={["Fresh", "Approved", "Open", "Received/Closed", "Partialy Received", "Cancelled"]}
                                                        value={formData.status}
                                                        onChange={(event, newValue) => setFormData({ ...formData, status: newValue })}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" className="small-input" />}
                                                        // sx={{ width: 300 }}
                                                        className="small-autocomplete"
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} pb={6}>
                                                <Box sx={{ overflowX: 'auto', marginBottom: "1rem" }}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 800, width: '100%' }} aria-label="responsive table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {['ITEM CODE', 'ITEM NAME', 'UOM', 'Sunit', 'HSN Code', 'Batch No.', 'Expiry Delivery date', 'Item Type', 'Currency', 'Quantity', 'Scheme', 'Price', 'Discount', 'Net', 'Tax%', 'Tax Amt'
                                                                        // 'CGST', 'CGST Amount', 'SGST', 'SGST Amount', 'IGST', 'IGST Amount'
                                                                        , 'purchase cost per unit', 'Landed cost per unit', 'Total', 'Action'].map((header) => (
                                                                            <TableCell key={header} sx={{ fontSize: '12px', minWidth: header === 'Action' ? 80 : 150 }}>
                                                                                {header}
                                                                            </TableCell>
                                                                        ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {rows.map((row, rowIndex) => (
                                                                    <TableRow key={rowIndex}>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 200 }}>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="combo-box-demo"
                                                                                options={item}
                                                                                getOptionLabel={(option) => option.item_code || ''}
                                                                                renderOption={(props, option) => (
                                                                                    <li {...props}>{option.item_code}-{option.item_name}</li>
                                                                                )}
                                                                                style={{ height: 51 }}
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    height: 20,
                                                                                    fontSize: '12px'
                                                                                }}
                                                                                value={row.newValue}
                                                                                onChange={(event, newValue) => ItemSelect(newValue, row)}
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} placeholder="Item code" variant="outlined" sx={{ fontSize: '12px' }} />
                                                                                )}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 200 }}>
                                                                            <MDInput
                                                                                type="text"
                                                                                variant="outlined"
                                                                                value={row.item_name}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>

                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 200 }}>
                                                                            <MDInput
                                                                                type="text"
                                                                                value={row.item_uom}
                                                                                sx={{ fontSize: '12px' }}
                                                                                disabled={true}
                                                                                variant="outlined"
                                                                            // disablePortal
                                                                            // id="combo-box-demo"
                                                                            // options={row?.uom_list}
                                                                            // getOptionLabel={(option) => option.item_uom?.name || ''}
                                                                            // renderOption={(props, option) => (
                                                                            //     <li {...props}>{option.item_uom?.name}</li>
                                                                            // )}
                                                                            // style={{ height: 51 }}
                                                                            // sx={{
                                                                            //     width: '100%',
                                                                            //     height: 20,
                                                                            //     fontSize: '12px'
                                                                            // }}
                                                                            // value={row.newValue_uom}
                                                                            // onChange={(event, newValue) => ItemSelectUom(newValue, row)}
                                                                            // renderInput={(params) => (
                                                                            //     <TextField {...params} placeholder="Item Uom" variant="outlined" sx={{ fontSize: '12px' }} />
                                                                            // )}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 200 }}>
                                                                            <MDInput
                                                                                type="text"
                                                                                // value={row.sunit}
                                                                                value={row.item_uom}
                                                                                sx={{ fontSize: '12px' }}
                                                                                disabled={true}
                                                                                variant="outlined"
                                                                            // disablePortal
                                                                            // id="combo-box-demo"
                                                                            // options={row?.uom_list}
                                                                            // getOptionLabel={(option) => option.item_uom?.name || ''}
                                                                            // renderOption={(props, option) => (
                                                                            //     <li {...props}>{option.item_uom?.name}</li>
                                                                            // )}
                                                                            // style={{ height: 51 }}
                                                                            // sx={{
                                                                            //     width: '100%',
                                                                            //     height: 20,
                                                                            //     fontSize: '12px'
                                                                            // }}
                                                                            // value={row.newValue_uom}
                                                                            // onChange={(event, newValue) => ItemSelectUom(newValue, row)}
                                                                            // renderInput={(params) => (
                                                                            //     <TextField {...params} placeholder="Item Uom" variant="outlined" sx={{ fontSize: '12px' }} />
                                                                            // )}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="text"
                                                                                variant="outlined"
                                                                                value={row.hsn_code}
                                                                                onChange={(event) => {
                                                                                    itemquantityChange({ target: { name: 'hsn_code', value: event.target.value } }, row);
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="text"
                                                                                variant="outlined"
                                                                                value={row.receiving_site}
                                                                                onChange={(event) => {
                                                                                    itemquantityChange({ target: { name: 'receiving_site', value: event.target.value } }, row);
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="date"
                                                                                name="expiry_delivery_date"
                                                                                variant="outlined"
                                                                                value={row.expiry_delivery_date}
                                                                                onChange={(event) => {
                                                                                    itemquantityChange({ target: { name: 'expiry_delivery_date', value: event.target.value } }, row);
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100, }}>
                                                                            <Select
                                                                                value={row.itemtype || 'Finished Goods'}
                                                                                onChange={(event) => handleItemTypeChange(event.target.value, rowIndex, "itemtype")}
                                                                                sx={{ fontSize: '12px', width: '100%', height: "43px" }}
                                                                            >
                                                                                <MenuItem value="Finished Goods">Finished Goods</MenuItem>
                                                                                <MenuItem value="Bad Return">Bad Return</MenuItem>
                                                                            </Select>
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100, }}>
                                                                            <Select
                                                                                value={row.currency || 'INR'}
                                                                                onChange={(event) => handleItemTypeChange(event.target.value, rowIndex, 'currency')}
                                                                                sx={{ fontSize: '12px', width: '100%', height: "43px" }}
                                                                            >
                                                                                <MenuItem value="INR">INR</MenuItem>
                                                                            </Select>
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.quantity}
                                                                                name='quantity'
                                                                                onChange={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value >= 1 || event.target.value === "") {
                                                                                        itemquantityChange(event, row);
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value < 1) {
                                                                                        itemquantityChange({ target: { name: 'price', value: 1 } }, row);
                                                                                    }
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100, }}>
                                                                            <Select
                                                                                value={row.skim || 'None'}
                                                                                onChange={(event) => handleSkimChange(event.target.value, rowIndex)}
                                                                                sx={{ fontSize: '12px', width: '100%', height: "43px" }}
                                                                            >
                                                                                <MenuItem value="None">None</MenuItem>
                                                                                <MenuItem value="Free">Free</MenuItem>
                                                                            </Select>
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                name='price'
                                                                                value={row.price}
                                                                                onChange={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value >= 1 || event.target.value === "") {
                                                                                        itemquantityChange(event, row);
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value < 1) {
                                                                                        itemquantityChange({ target: { name: 'price', value: 1 } }, row);
                                                                                    }
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                name='discount'
                                                                                value={row.discount}
                                                                                onChange={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value >= 1 || event.target.value === "") {
                                                                                        itemquantityChange(event, row);
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    const value = parseFloat(event.target.value);
                                                                                    if (value < 1) {
                                                                                        itemquantityChange({ target: { name: 'price', value: 1 } }, row);
                                                                                    }
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.net}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.vat}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        {/* <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.ptr_di}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell> */}
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.taxa_ble}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        {/* <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.cgst}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.cgst_amount}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.sgst}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.sgst_amount}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.igst}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.igst_amount}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell> */}
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                name="purchase_cost_per_unit"
                                                                                value={row.purchase_cost_per_unit}
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value;
                                                                                    // if (value >= 1 || event.target.value === "") {
                                                                                    itemquantityChange(event, row);
                                                                                    // }
                                                                                }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                name="landed_cost_per_unit"
                                                                                value={row.landed_cost_per_unit}
                                                                                onChange={(event) => {
                                                                                    const value = event.target.value;
                                                                                    if (value >= 1 || event.target.value === "") {
                                                                                        itemquantityChange(event, row);
                                                                                    }
                                                                                }}
                                                                                // onBlur={(event) => {
                                                                                //     const value = parseFloat(event.target.value);
                                                                                //     if (value < 1) {
                                                                                //         itemquantityChange({ target: { name: 'landed_cost_per_unit', value: 1 } }, row);
                                                                                //     }
                                                                                // }}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 100 }}>
                                                                            <MDInput
                                                                                type="number"
                                                                                variant="outlined"
                                                                                value={row.total}
                                                                                disabled={true}
                                                                                sx={{ fontSize: '12px' }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontSize: '12px', minWidth: 80 }}>
                                                                            <MDButton
                                                                                variant="outlined"
                                                                                color="info"
                                                                                iconOnly
                                                                                onClick={() => handleRemoveRow(rowIndex)}
                                                                                sx={{ fontSize: '12px' }}
                                                                            >
                                                                                <Icon fontSize="small">clear</Icon>
                                                                            </MDButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                <MDButton variant="contained" color="secondary" onClick={handleAddRow}>
                                                    Add Row
                                                </MDButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr></hr>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <MDBox pb={2}>
                                                    <InputLabel sx={{ mb: 1 }}>Customer Note</InputLabel>
                                                    <MDInput
                                                        type="text"
                                                        variant="outlined"
                                                        sx={{ width: 300 }}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <MDBox mx={2} px={6} py={2} pt={2} bgColor="light">
                                                    <Grid
                                                        container
                                                        // rowSpacing={1}
                                                        columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 16 }}
                                                                variant="caption"
                                                                color="text"
                                                                fontWeight="regular"
                                                            >
                                                                Discount
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 17 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                INR {parseFloat(sums.discount).toFixed(2)}
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 16 }}
                                                                variant="caption"
                                                                color="text"
                                                                fontWeight="regular"
                                                            >
                                                                Net Total
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 17 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                INR {parseFloat(sums.net).toFixed(2)}
                                                            </MDTypography>
                                                        </Grid>
                                                        {/* <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 16 }}
                                                                variant="caption"
                                                                color="text"
                                                                fontWeight="regular"
                                                            >
                                                                Excise
                                                            </MDTypography>
                                                        </Grid> */}
                                                        {/* <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 17 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                INR {parseFloat(sums.excise).toFixed(2)}
                                                            </MDTypography>
                                                        </Grid> */}
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 16 }}
                                                                variant="caption"
                                                                color="text"
                                                                fontWeight="regular"
                                                            >
                                                                Tax
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <MDTypography
                                                                style={{ fontSize: 17 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                INR {parseFloat(sums.vat).toFixed(2)}
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6} pb={1} pt={2}>
                                                            <MDTypography
                                                                style={{ fontSize: 18 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                Total
                                                            </MDTypography>
                                                        </Grid>
                                                        <Grid item xs={6} pb={1} pt={2}>
                                                            <MDTypography
                                                                style={{ fontSize: 18 }}
                                                                variant="caption"
                                                                color="dark"
                                                                fontWeight="medium"
                                                            >
                                                                INR {parseFloat(sums.total).toFixed(2)}
                                                            </MDTypography>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                                                    <Grid item xs={2} ml={3}>
                                                        <MDBox sx={{ display: 'flex' }}>
                                                            <MDButton variant="gradient" disabled={isSubmit} color="info" type="submit" fullWidth>
                                                                {isSubmit ?
                                                                    <CircularProgress color="white" size={24}
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            marginTop: '-12px',
                                                                            marginLeft: '-12px',
                                                                        }} />
                                                                    : 'Save'
                                                                }
                                                            </MDButton>
                                                            <MDButton variant="gradient" disabled={isSubmit} color="secondary" type="submit" fullWidth sx={{ marginLeft: '15px' }} onClick={handleBack}>
                                                                cancel
                                                            </MDButton>
                                                        </MDBox>
                                                        <MDBox>

                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Add_Po;
