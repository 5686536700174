import React from 'react';

function Items() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
  };

  return (
    <div style={style}>
      <h2>Coming Soon</h2>
    </div>
  );
}
export default Items;